import { render, staticRenderFns } from "./DealershipsList.vue?vue&type=template&id=42b907ba&scoped=true"
import script from "./DealershipsList.vue?vue&type=script&lang=ts"
export * from "./DealershipsList.vue?vue&type=script&lang=ts"
import style0 from "./DealershipsList.vue?vue&type=style&index=0&id=42b907ba&prod&lang=scss&scoped=true"
import style1 from "./DealershipsList.vue?vue&type=style&index=1&id=42b907ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b907ba",
  null
  
)

export default component.exports