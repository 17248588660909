
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
// Services
import {
  useArea,
  useCity,
  useDealerships,
  useGroup,
  useRegion,
  useState,
} from "@/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Interfaces
import { IBodyDealerships } from "@core/services/interfaces/business/dealerships/IBodySearch";
import {
  IDealerships,
  IResponseListDealerships,
} from "@core/services/interfaces/business/dealerships/IDealershipsService";
import { IResponseListGroup } from "@core/services/interfaces/business/group/IGroupService";
import { IResponseListArea } from "@core/services/interfaces/covarege-area/area/IAreaService";
import {
  ICityApi,
  IResponseListCounties,
} from "@core/services/interfaces/covarege-area/city/ICityService";
import { IResponseListRegion } from "@core/services/interfaces/covarege-area/region/IRegionService";
import { IResponseListState } from "@core/services/interfaces/covarege-area/state/IStateService";
import { IOption } from "@core/services/interfaces/IUtil";
import useTable, { IFilterOptions } from "@core/utils/useTable";
import { Ref, ref } from "@vue/composition-api";
import { AxiosResponse } from "axios";
import {
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import { RouterLink } from "vue-router";
import vSelect from "vue-select";
import DealershipsListFilter from "./DealershipsListFilter.vue";

// Interfaces
interface IExportDealership {
  Código: number;
  "Razão Social": string;
  "Nome Fantasia": string;
  CNPJ: string;
  "Cod. Faturamento": number;
  Matriz: string;
  Categoria: string;
  "Área de Influência": string;
  "Grupo Empresa": string;
  "Região Operacional": string;
  CEP: string;
  UF: string;
  Estado: string;
  Município: string;
  "Cod. IBGE": string | undefined;
  Bairro: string;
  Número: string;
  Complemento: string;
  Logradouro: string;
  Latitude: number;
  Longitude: number;
  Cadastrada: string;
  Associada: string;
  "Pendência Financeira": string;
  Status: string;
  Montadora: string;
  Observações: string;
}

interface IBusinessFilter {
  groupFilter: string;
  areaFilter: string;
  regionFilter: string;
  stateFilter: string;
  cityFilter: string;
  matrizFilter: boolean | null;
  associadaFilter: boolean | null;
  financeFilter: boolean | null;
  statusFilter: boolean | null;
  newFilter: boolean | null;
  brandFilter: boolean | null;
  searchQuery: string;
}

@Component({
  name: "Dealerships",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    BPagination,
    BTable,
    AvButton,
    AvExportButton,
    DealershipsListFilter,
    AvSkeletonTable,
    RouterLink,
  },
  directives: {
    Ripple,
  },
})
export default class Dealerships extends Vue {
  // Data
  groupTitle = this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE;
  tableColumns = [
    { key: "GrupoEmpresaNome", label: this.groupTitle, sortable: true },
    { key: "RazaoSocial", label: "Razão Social", sortable: true },
    { key: "NomeFantasia", label: "Nome Fantasia", sortable: true },
    { key: "CNPJ", label: "CNPJ", sortable: true },
    { key: "Codigo", label: "Código", sortable: true },
    { key: "CodigoFaturamento", label: "Cód. Matriz", sortable: true },
    { key: "UF", label: "Estado", sortable: true },
    { key: "Municipio", label: "Município", sortable: true },
    { key: "AreaInfluenciaNome", label: "Área de Influência", sortable: true },
    {
      key: "RegiaoOperacionalNome",
      label: "Região Operacional",
      sortable: true,
    },
    { key: "Matriz", label: "Matriz", sortable: true },
    { key: "Montadora", label: "Montadora", sortable: true },
    { key: "PendenciaFinanceira", label: "Sit. Financeira", sortable: true },
    { key: "Status", label: "Ativo", sortable: true },
    {
      key: "Associada",
      label: `Empresa ${
        this.$store.state["appConfig"].params.EMP_FABRICANTE || "Associada"
      }`,
      sortable: true,
    },
    {
      key: "Cadastrada",
      label: "Cadastrada",
      sortable: true,
    },
    { key: "actions", label: "Ações" },
  ];
  cities: ICityApi[] = [];

  useTable = new useTable(this.tableColumns, {
    sortBy: "NomeFantasia",
    customFilter: this.customFilter,
  });

  // LifeCycle
  created() {
    this.fetchListBusiness();
    this.loadFilters();
  }

  // Filtros
  groupFilter: Ref<string> = ref("");
  areaFilter: Ref<string> = ref("");
  regionFilter: Ref<string> = ref("");
  stateFilter: Ref<string> = ref("");
  cityFilter: Ref<string> = ref("");
  matrizFilter: Ref<boolean | null> = ref(null);
  associadaFilter: Ref<boolean | null> = ref(null);
  financeFilter: Ref<boolean | null> = ref(null);
  statusFilter: Ref<boolean | null> = ref(null);
  newFilter: Ref<boolean | null> = ref(null);
  brandFilter: Ref<boolean | null> = ref(null);
  groupOptions: IOption[] = [];
  areaOptions: IOption[] = [];
  regionOptions: IOption[] = [];
  stateOptions: IOption[] = [];
  cityOptions: IOption[] = [];
  confirmationOptions: IOption[] = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];
  statusOptions: IOption[] = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];
  financeOptions: IOption[] = [
    { label: "Adimplente", value: false },
    { label: "Inadimplente", value: true },
  ];

  // Watchs
  customFilter(item: IDealerships): boolean {
    let filtered = {
      groupFilter: true,
      areaFilter: true,
      regionFilter: true,
      stateFilter: true,
      cityFilter: true,
      matrizFilter: true,
      associadaFilter: true,
      financeFilter: true,
      statusFilter: true,
      newFilter: true,
      brandFilter: true,
    };

    if (this.groupFilter.value) {
      filtered.groupFilter = item.GrupoEmpresaId == this.groupFilter.value;
    }

    if (this.areaFilter.value) {
      filtered.areaFilter = item.AreaInfluenciaId == this.areaFilter.value;
    }

    if (this.regionFilter.value != "") {
      const reg = new RegExp(this.regionFilter.value, "g");
      filtered.regionFilter = item.RegiaoOperacionalNome.match(reg)
        ? true
        : false;
    }

    if (this.stateFilter.value) {
      filtered.stateFilter = item.EstadoId == this.stateFilter.value;
    }

    if (this.cityFilter.value) {
      filtered.cityFilter = item.MunicipioId == this.cityFilter.value;
    }

    if (typeof this.matrizFilter.value == "boolean") {
      filtered.matrizFilter = item.Matriz == this.matrizFilter.value;
    }

    if (typeof this.associadaFilter.value == "boolean") {
      filtered.associadaFilter = item.Associada == this.associadaFilter.value;
    }

    if (typeof this.financeFilter.value == "boolean") {
      filtered.financeFilter =
        item.PendenciaFinanceira == this.financeFilter.value;
    }

    if (typeof this.statusFilter.value == "boolean") {
      filtered.statusFilter = item.Status == this.statusFilter.value;
    }

    if (typeof this.newFilter.value == "boolean") {
      filtered.newFilter = item.Cadastrada == this.newFilter.value;
    }

    if (typeof this.brandFilter.value == "boolean") {
      filtered.brandFilter = item.Montadora == this.brandFilter.value;
    }

    // Checando se todos os filtros foram atendidos
    return Object.entries(filtered).filter((item) => !item[1]).length == 0;
  }

  @Watch("groupFilter.value")
  @Watch("areaFilter.value")
  @Watch("regionFilter.value")
  @Watch("stateFilter.value")
  @Watch("cityFilter.value")
  @Watch("matrizFilter.value")
  @Watch("associadaFilter.value")
  @Watch("financeFilter.value")
  @Watch("statusFilter.value")
  @Watch("newFilter.value")
  @Watch("brandFilter.value")
  refetchData() {
    this.saveFilter();
    this.useTable.filterItems();
  }

  @Watch("stateFilter.value")
  getCityByStateId() {
    if (this.stateFilter.value && this.stateFilter.value != "") {
      this.cityFilter.value = "";
      this.cityOptions = this.cities
        .filter((city) => city.EstadoId === this.stateFilter.value)
        .map((city) => ({
          label: city.Nome,
          value: city.Id,
        }));
    } else {
      this.cityOptions = this.cities.map((city) => ({
        label: city.Nome,
        value: city.Id,
      }));
    }
  }

  async fetchListBusiness() {
    const amountPerRequest = 5000;
    let requests = [];
    let total = 0;
    let success = true;
    let businessData: IDealerships[] = [];

    await useDealerships
      .requestList({
        paginate: true,
        draw: 1,
        length: amountPerRequest,
      })
      .then((response: IResponseListDealerships) => {
        total = response.data.Data.recordsTotal;
        businessData = response.data.Data.data;
      })
      .catch(() => {
        success = false;
      });

    const pages = Math.ceil(total / amountPerRequest);
    for (let page = 2; page <= pages; page++) {
      requests.push(
        useDealerships.requestList({
          paginate: true,
          draw: page,
          length: amountPerRequest,
        })
      );
    }

    await Promise.all(requests)
      .then((response: IResponseListDealerships[]) => {
        this.useTable.listTable.value = response.reduce(
          (
            prevValue: IDealerships[],
            currentValue: IResponseListDealerships
          ) => {
            return prevValue.concat(currentValue.data.Data.data);
          },
          businessData
        );
      })
      .catch(() => {
        success = false;
      });

    this.useTable.filterItems();
    this.useTable.firstRequest.value = false;
    if (!success) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Erro ao buscar Empresas!",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  }

  clearFilters() {
    this.groupFilter.value = "";
    this.areaFilter.value = "";
    this.regionFilter.value = "";
    this.stateFilter.value = "";
    this.cityFilter.value = "";
    this.matrizFilter.value = null;
    this.associadaFilter.value = null;
    this.financeFilter.value = null;
    this.statusFilter.value = null;
    this.newFilter.value = null;
    this.brandFilter.value = null;
    this.useTable.searchQuery.value = "";

    localStorage.removeItem("businessFilter");
  }

  saveFilter() {
    const filters: IBusinessFilter = {
      groupFilter: this.groupFilter.value,
      areaFilter: this.areaFilter.value,
      regionFilter: this.regionFilter.value,
      stateFilter: this.stateFilter.value,
      cityFilter: this.cityFilter.value,
      matrizFilter: this.matrizFilter.value,
      associadaFilter: this.associadaFilter.value,
      financeFilter: this.financeFilter.value,
      statusFilter: this.statusFilter.value,
      newFilter: this.newFilter.value,
      brandFilter: this.brandFilter.value,
      searchQuery: this.useTable.searchQuery.value,
    };

    localStorage.setItem("businessFilter", JSON.stringify(filters));
  }

  loadFilters() {
    // Load LocalStore
    const filters: IBusinessFilter | null = JSON.parse(
      localStorage.getItem("businessFilter") || "null"
    );

    if (filters) {
      this.groupFilter.value = filters.groupFilter;
      this.areaFilter.value = filters.areaFilter;
      this.regionFilter.value = filters.regionFilter;
      this.stateFilter.value = filters.stateFilter;
      this.cityFilter.value = filters.cityFilter;
      this.matrizFilter.value = filters.matrizFilter;
      this.associadaFilter.value = filters.associadaFilter;
      this.financeFilter.value = filters.financeFilter;
      this.statusFilter.value = filters.statusFilter;
      this.newFilter.value = filters.newFilter;
      this.brandFilter.value = filters.brandFilter;
      this.useTable.searchQuery.value = filters.searchQuery;
    }

    // Groups
    useGroup
      .requestListGroup({
        paginate: false,
        draw: 1,
      })
      .then((response: IResponseListGroup) => {
        this.groupOptions = response.data.data
          .sort((a, b) => {
            return a.Nome.localeCompare(b.Nome);
          })
          .map((group) => ({
            label: group.Nome,
            value: group.Id,
          }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Grupos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    // Areas
    useArea
      .requestList({
        paginate: false,
        draw: 1,
        length: 10000,
      })
      .then((response: IResponseListArea) => {
        this.areaOptions = response.data.Data.data
          .sort((a, b) => {
            return a.Nome.localeCompare(b.Nome);
          })
          .map((area) => ({
            value: area.Id,
            label: area.Nome,
          }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de áreas de influência!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    useRegion
      .requestList({ draw: 1, length: 100000 })
      .then((response: AxiosResponse<IResponseListRegion>) => {
        this.regionOptions = response.data.Data.data
          .sort((a, b) => {
            return a.Nome.localeCompare(b.Nome);
          })
          .map((region) => ({
            label: region.Nome,
            value: region.Nome,
          }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Região Operacional!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    useState
      .requestList({
        paginar: false,
        municipios: [],
        columns: [
          {
            data: "Nome",
          },
        ],
        order: [
          {
            column: 0,
            dir: "asc",
          },
        ],
      })
      .then((response: IResponseListState) => {
        this.stateOptions = response.data.Data.data
          .sort((a, b) => {
            return a.Nome.localeCompare(b.Nome);
          })
          .map((state) => ({
            label: state.Nome + " - " + state.Sigla,
            value: state.Id,
          }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de estados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    useCity
      .requestList({
        paginar: false,
        draw: 1,
        columns: [
          {
            data: "Nome",
          },
        ],
        order: [
          {
            column: 0,
            dir: "asc",
          },
        ],
      })
      .then((response: IResponseListCounties) => {
        this.cities = response.data.Data.data;
        this.cityOptions = response.data.Data.data
          .sort((a, b) => {
            return a.Nome.localeCompare(b.Nome);
          })
          .map((city) => ({
            label: city.Nome,
            value: city.Id,
          }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de municípios!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  handlerSidebarAdd(): void {
    this.$router.push({ name: "admin-panel-dealerships-add" });
  }

  handlerSidebarEdit(dealershipId: number | null): void {
    this.$router.push("/admin-panel/dealerships/edit/" + dealershipId);
  }

  mapFilterToBody(options: IFilterOptions): IBodyDealerships {
    let body = this.useTable.mapFilterToBody(options);
    body.userdata = {};

    if (this.groupFilter.value && this.groupFilter.value != "")
      body.userdata.GrupoEmpresaId = this.groupFilter.value;

    if (this.areaFilter.value && this.areaFilter.value != "")
      body.userdata.AreaInfluenciaId = this.areaFilter.value;

    if (this.regionFilter.value != "")
      body.userdata.RegiaoOperacionalNome = this.regionFilter.value;

    if (this.stateFilter.value) body.userdata.EstadoId = this.stateFilter.value;

    if (this.cityFilter.value)
      body.userdata.MunicipioId = this.cityFilter.value;

    if (this.matrizFilter.value != null)
      body.userdata.Matriz = this.matrizFilter.value;

    if (this.associadaFilter.value != null)
      body.userdata.Associada = this.associadaFilter.value;

    if (this.financeFilter.value != null)
      body.userdata.PendenciaFinanceira = this.financeFilter.value;

    if (this.statusFilter.value != null)
      body.userdata.Status = this.statusFilter.value;

    if (this.newFilter.value != null)
      body.userdata.Cadastrada = this.newFilter.value;

    if (this.brandFilter.value != null)
      body.userdata.Montadora = this.brandFilter.value;

    return body;
  }

  async getDealerships() {
    return new Promise(async (resolve, reject) => {
      let data: any = [];
      let requests: Array<Promise<IResponseListDealerships>> = [];

      let amountPerRequest = 5000;
      let pages = Math.ceil(this.useTable.display.value / amountPerRequest);

      let body = this.mapFilterToBody({
        perPage: this.useTable.perPage.value,
        currentPage: this.useTable.currentPage.value,
        searchQuery: this.useTable.searchQuery.value,
        sortyBy: this.useTable.sortBy.value,
        isSortDirDesc: this.useTable.isSortDirDesc.value,
        filters: this.useTable.filters,
      });

      for (let page = 1; page <= pages; page++) {
        requests.push(
          useDealerships.requestList({
            ...body,
            paginate: true,
            draw: page,
            length: amountPerRequest,
          })
        );
      }

      await Promise.all(requests)
        .then((response: IResponseListDealerships[]) => {
          // Unir os arrays de resultados em um único array
          data = response.reduce(
            (
              prevValue: IExportDealership[],
              currentValue: IResponseListDealerships
            ) => {
              const dealership: IExportDealership[] =
                currentValue.data.Data.data.map((dealership) => ({
                  Código: dealership.Codigo,
                  "Razão Social": dealership.RazaoSocial,
                  "Nome Fantasia": dealership.NomeFantasia,
                  CNPJ: dealership.CNPJ,
                  "Cod. Faturamento": dealership.CodigoFaturamento,
                  Matriz: dealership.Matriz ? "Matriz" : "Filial",
                  Categoria: dealership.CategoriaNome,
                  "Área de Influência": dealership.AreaInfluenciaNome,
                  "Grupo Empresa": dealership.GrupoEmpresaNome,
                  "Região Operacional": dealership.RegiaoOperacionalNome,
                  CEP: dealership.CEP,
                  UF: dealership.UF,
                  Estado: dealership.Estado,
                  Município: dealership.Municipio,
                  "Cod. IBGE": dealership.CidadeCodigoIbge,
                  Bairro: dealership.Bairro,
                  Número: dealership.Numero,
                  Complemento: dealership.Complemento,
                  Logradouro: dealership.Logradouro,
                  Latitude: dealership.Latitude,
                  Longitude: dealership.Longitude,
                  Cadastrada: dealership.Cadastrada ? "Sim" : "Não",
                  Associada: dealership.Associada ? "Sim" : "Não",
                  "Pendência Financeira": dealership.PendenciaFinanceira
                    ? "Sim"
                    : "Não",
                  Status: dealership.Status ? "Ativo" : "Inativo",
                  Montadora: dealership.Montadora ? "Sim" : "Não",
                  Observações: dealership.Observacoes,
                }));
              return prevValue.concat(dealership);
            },
            []
          );

          return resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    });
  }
}
